import { Link } from "react-router-dom";

export default function Footer(props: any) {
  return (
    <footer className="bg-neutral-dark text-white">
      <ul className="mx-4 space-x-5 flex items-center text-sm">
        <img
          className="mt-4"
          style={{ width: "100px", height: "auto" }}
          src={process.env.PUBLIC_URL + "/main_logo.svg"}
          alt="Company Logo"
        />

        <li>
          <Link to="/about">About Us</Link>
        </li>
        <li>
          <Link to="/products">Products</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
      <p className="my-5 ml-5 text-sm text-center">
        Copyright @ 2024 Seonho Medix
      </p>
    </footer>
  );
}
