import { useTranslation } from "react-i18next";
const mainImageSrc = process.env.PUBLIC_URL + "/landing_page_illustration.png";
console.log(mainImageSrc);
export default function Homepage() {
  const { t } = useTranslation();

  return (
    <section className="bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-10 flex flex-col items-center">
        <img
          data-aos="fade-up"
          data-aos-delay="200"
          src={mainImageSrc}
          alt="main illustration"
          className="w-[50%]"
        />

        {/* Hero content */}
        <div className="text-center mt-10 md:mt-20">
          {/* Section header */}
          <h1
            data-aos="fade-up"
            className="text-5xl font-extrabold text-primary mb-6"
          >
            {t("company_title")}
          </h1>
          <p
            className="text-xl text-gray-600 mb-8 lg:w-[70%] mx-auto"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {t("intro")}
          </p>

          {/* Call to action buttons */}
          <div className="flex justify-center space-x-4">
            <a
              className="btn bg-primary text-white hover:bg-purple-700 px-6 py-3 rounded-md transition duration-300"
              href="/products"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              {t("main_cta")}
            </a>
            <a
              className="btn bg-gray-500 text-white hover:bg-gray-400 px-6 py-3 rounded-md transition duration-300"
              href="/contact"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              {t("secondary_cta")}
            </a>
          </div>
        </div>
      </div>
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <p className="font-bold text-xl  text-gray-700">
                {t("main_content")}
              </p>
            </div>
            {/* Items */}
            <div
              className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-2 lg:gap-16 items-start md:max-w-2xl lg:max-w-none"
              data-aos-id-blocks
            >
              <div
                className="relative flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-anchor="[data-aos-id-blocks]"
              >
                <svg
                  className="w-16 h-16 mb-4" // This controls the size of the circle and the overall component
                  viewBox="0 0 64 64" // This ensures the circle encompasses the entire area intended
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="fill-current text-purple-600" // This styles the circle's color
                    cx="32"
                    cy="32"
                    r="32" // Adjust the radius as needed to fit the SVG icon appropriately
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512" // This viewBox is specific to the baby icon SVG you provided
                    x="4" // Adjust this to center the SVG icon within the circle
                    y="8" // Adjust this to center the SVG icon within the circle
                    width="48" // This sizes the SVG icon to fit within the circular background
                    height="48" // This sizes the SVG icon to fit within the circular background
                    fill="white" // Adjust the fill color as needed
                  >
                    <path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L550.2 352H592c26.5 0 48-21.5 48-48V176c0-26.5-21.5-48-48-48H516h-4-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48H48c-26.5 0-48 21.5-48 48V304c0 26.5 21.5 48 48 48H156.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123z" />
                  </svg>
                </svg>
                <h4 className="h4 mb-2">{t("i18n_last_item_title")}</h4>
                <p className="text-lg text-gray-400 text-center">
                  {t("i18n_last_item_content")}
                </p>
              </div>

              {/* 2nd item */}
              <div
                className="relative flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-anchor="[data-aos-id-blocks]"
              >
                <svg
                  className="w-16 h-16 mb-4"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="fill-current text-purple-600"
                    cx="32"
                    cy="32"
                    r="32"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512" // This viewBox is specific to the baby icon SVG you provided
                    x="12" // Adjust this to center the SVG icon within the circle
                    y="8" // Adjust this to center the SVG icon within the circle
                    width="48" // This sizes the SVG icon to fit within the circular background
                    height="48" // This sizes the SVG icon to fit within the circular background
                    fill="white" // Adjust the fill color as needed
                  >
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zm-312 8v64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96V280c0 13.3 10.7 24 24 24s24-10.7 24-24V120c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                </svg>
                <h4 className="h4 mb-2">{t("i18n_second_item_title")}</h4>
                <p className="text-lg text-gray-400 text-center">
                  {t("i18n_second_item_content")}
                </p>
              </div>

              <div
                className="relative flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-anchor="[data-aos-id-blocks]"
              >
                <svg
                  className="w-16 h-16 mb-4" // This controls the size of the circle and the overall component
                  viewBox="0 0 64 64" // This ensures the circle encompasses the entire area intended
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="fill-current text-purple-600" // This styles the circle's color
                    cx="32"
                    cy="32"
                    r="32" // Adjust the radius as needed to fit the SVG icon appropriately
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512" // This viewBox is specific to the baby icon SVG you provided
                    x="8" // Adjust this to center the SVG icon within the circle
                    y="8" // Adjust this to center the SVG icon within the circle
                    width="48" // This sizes the SVG icon to fit within the circular background
                    height="48" // This sizes the SVG icon to fit within the circular background
                    fill="white" // Adjust the fill color as needed
                  >
                    <path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z" />
                  </svg>
                </svg>
                <h4 className="h4 mb-2">{t("i18n_first_item_content")}</h4>
                <p className="text-lg text-gray-400 text-center">
                  {t("i18n_first_item_content")}
                </p>
              </div>

              <div
                className="relative flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-anchor="[data-aos-id-blocks]"
              >
                <svg
                  className="w-16 h-16 mb-4" // This controls the size of the circle and the overall component
                  viewBox="0 0 64 64" // This ensures the circle encompasses the entire area intended
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="fill-current text-purple-600" // This styles the circle's color
                    cx="32"
                    cy="32"
                    r="32" // Adjust the radius as needed to fit the SVG icon appropriately
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512" // This viewBox is specific to the baby icon SVG you provided
                    x="8" // Adjust this to center the SVG icon within the circle
                    y="8" // Adjust this to center the SVG icon within the circle
                    width="48" // This sizes the SVG icon to fit within the circular background
                    height="48" // This sizes the SVG icon to fit within the circular background
                    fill="white" // Adjust the fill color as needed
                  >
                    <path d="M152 88a72 72 0 1 1 144 0A72 72 0 1 1 152 88zM39.7 144.5c13-17.9 38-21.8 55.9-8.8L131.8 162c26.8 19.5 59.1 30 92.2 30s65.4-10.5 92.2-30l36.2-26.4c17.9-13 42.9-9 55.9 8.8s9 42.9-8.8 55.9l-36.2 26.4c-13.6 9.9-28.1 18.2-43.3 25V288H128V251.7c-15.2-6.7-29.7-15.1-43.3-25L48.5 200.3c-17.9-13-21.8-38-8.8-55.9zm89.8 184.8l60.6 53-26 37.2 24.3 24.3c15.6 15.6 15.6 40.9 0 56.6s-40.9 15.6-56.6 0l-48-48C70 438.6 68.1 417 79.2 401.1l50.2-71.8zm128.5 53l60.6-53 50.2 71.8c11.1 15.9 9.2 37.5-4.5 51.2l-48 48c-15.6 15.6-40.9 15.6-56.6 0s-15.6-40.9 0-56.6L284 419.4l-26-37.2z" />
                  </svg>
                </svg>
                <h4 className="h4 mb-2">{t("i18n_third_item_title")}</h4>
                <p className="text-lg text-gray-400 text-center">
                  {t("i18n_third_item_content")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
