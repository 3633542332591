import { getDatabase, ref, set } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../data/firebaseConfig";
import { nanoid } from "nanoid";
import AnnounceTable from "./AnnounceTable";
import { useTranslation } from "react-i18next";

const app = initializeApp(firebaseConfig);

export default function Announce() {
  const { t } = useTranslation();

  return (
    <div className="px-4 sm:px-6 py-10">
      <h1 className="text-3xl font-semibold text-center mb-10">
        {t("i18n_announcements_title")}
      </h1>
      <AnnounceTable />
    </div>
  );
}
