/* eslint-disable jsx-a11y/iframe-has-title */
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <section className="h-full flex items-center justify-center bg-white">
      <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 py-10">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6">{t("i18n_contact_title")}</h2>
          {/* <p className="text-lg text-gray-600">{t("i18n_contact_subtitle")}</p> */}
        </div>

        <div className="bg-gray-100 p-10 rounded-lg shadow-lg mb-8">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-2xl font-semibold mb-5">Andrew Yang</h3>
              <p className="text-gray-700 mb-4">
                Email:
                <a
                  href="mailto:shmedix@gmail.com"
                  className="text-blue-600 hover:text-blue-800 ml-2"
                >
                  shmedix@gmail.com
                </a>
              </p>
              <p className="text-gray-700">
                Phone : +82-31-461-0606 (South Korea)
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-5">
                {t("i18n_contact_address_title")}
              </h3>
              <p className="text-gray-700">{t("i18n_contact_address")}</p>
            </div>
          </div>
          <div className="flex justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.9044001812404!2d126.94230111140125!3d37.321092138047874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b67f04729ec9b%3A0x9cb3c77829e2ab47!2s17%20Songbu-ro%20291beonan-gil%2C%20Gunpo-si%2C%20Gyeonggi-do%2C%20South%20Korea!5e0!3m2!1sen!2sus!4v1705946223007!5m2!1sen!2sus"
              width="600"
              height="450"
              className="my-12"
              style={{ border: 0, maxWidth: "600px" }}
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
