import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";

export default function AnnounceDetail() {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState<any>(null);

  useEffect(() => {
    const db = getDatabase();
    const announcementRef = ref(db, `announcements/${id}`);

    onValue(announcementRef, (snapshot) => {
      setAnnouncement(snapshot.val());
    });
  }, [id]);

  if (!announcement) {
    return <div className="text-center py-10 text-xl">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 px-4 sm:px-6 py-10">
      <h1 className="text-3xl font-bold text-gray-800 mb-3">
        {announcement.title}
      </h1>
      <div className="mb-4 text-sm text-gray-500">
        <span>Published: {announcement.publishedDate}</span>
        {announcement.modifiedDate && (
          <span className="ml-4">
            Last Modified: {announcement.modifiedDate}
          </span>
        )}
      </div>
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: announcement.content }}
        />
      </div>
    </div>
  );
}
