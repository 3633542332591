import { useTranslation } from "react-i18next";

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "ko" : "en";
    i18n.changeLanguage(newLang);
  };

  return (
    <button
      className="border-2 border-blue-500 rounded-md p-2 bg-white hover:bg-blue-100 text-blue-500 font-semibold shadow-sm transition duration-300 ease-in-out"
      onClick={toggleLanguage}
    >
      Switch to {i18n.language === "en" ? "한국어" : "English"}
    </button>
  );
}
