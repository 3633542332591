import { useEffect, useRef, useState } from "react";
import PRODUCTS from "../data/products";
import { useTranslation } from "react-i18next";

const BigCategoryMap: Record<TProductCategory, string> = {
  "NICU/pediatric": "Neonatology",
  "OB/GYN,neonatal": "OB / GYN",
};

const CompanyMap: Record<TProductCategory, string> = {
  "NICU/pediatric": "Natus Medical",
  "OB/GYN,neonatal": "Clinical Innovations",
};

const CompanyLinkMap: Record<string, string> = {
  "NICU/pediatric": "https://natus.com/sensory/newborn-hearing-screening/",
  "OB/GYN,neonatal": "https://www.laborie.com/",
};

const SmallCategoryMap: Record<TProduct, string> = {
  "hearing-screening": "Hearing Screening",
  "eye-imaging": "Eye Imaging",
  "jaundice-mgmt": "Jaundice Management",
  delivery: "Delivery system for fetus",
};

function Products() {
  const { t } = useTranslation();

  const products = PRODUCTS;
  const firstCategory = Object.keys(BigCategoryMap)[0];

  const [openCategories, setOpenCategories] = useState<Record<string, boolean>>(
    { [firstCategory]: true }
  );
  const contentRefs = useRef<any>({});

  useEffect(() => {
    Object.keys(openCategories).forEach((category) => {
      const content = contentRefs.current[category];
      if (content) {
        content.style.maxHeight = openCategories[category]
          ? `${content.scrollHeight}px`
          : "0px";
      }
    });
  }, [openCategories]);

  useEffect(() => {
    const firstContent = contentRefs.current[firstCategory];
    if (firstContent) {
      // Delay the setting of initial maxHeight to ensure the content is fully rendered
      setTimeout(() => {
        firstContent.style.maxHeight = `${firstContent.scrollHeight}px`;
      }, 0);
    }
  });

  const toggleCategory = (category: string) => {
    setOpenCategories((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  // Group products by big category and small category
  const groupedProducts = products.reduce((grouped, product) => {
    (grouped[product.bigCategory] = grouped[product.bigCategory] || {})[
      product.smallCategory
    ] = [
      ...(grouped[product.bigCategory][product.smallCategory] || []),
      product,
    ];
    return grouped;
  }, {} as Record<TProductCategory, Record<TProduct, TProductItem[]>>);

  return (
    <>
      <section className="h-full flex items-center justify-center bg-white">
        <div className="w-full md:w-3/4 xl:w-2/3 px-4 sm:px-6 py-10">
          <div className="text-center mb-10">
            <h1 className="text-4xl font-semibold mb-4">
              {t("i18n_product_title")}
            </h1>
          </div>
          {Object.entries(groupedProducts).map(
            ([bigCategory, smallCategories]) => (
              <div key={bigCategory} className="mb-6">
                <button
                  onClick={() => toggleCategory(bigCategory)}
                  className="text-2xl font-semibold flex justify-between items-center w-full mb-6 bg-gray-50 p-4 rounded-md shadow"
                >
                  {BigCategoryMap[bigCategory as TProductCategory]}
                  <span className="text-sm">
                    {openCategories[bigCategory] ? "▼" : "►"}
                  </span>
                </button>
                {openCategories[bigCategory] && (
                  <p className="text-md font-normal mb-4">
                    {t("i18n_product_we_are_in")}
                    <a
                      href={CompanyLinkMap[bigCategory as TProductCategory]}
                      className="text-blue-600 hover:text-blue-800 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {CompanyMap[bigCategory as TProductCategory]}
                    </a>{" "}
                  </p>
                )}
                <div
                  ref={(el) => (contentRefs.current[bigCategory] = el)}
                  className="overflow-hidden transition-all duration-500 ease-in-out"
                >
                  {openCategories[bigCategory] &&
                    Object.entries(smallCategories).map(
                      ([smallCategory, products]) => (
                        <div key={smallCategory} className="mt-4">
                          <h3 className="text-xl font-semibold mb-4">
                            {SmallCategoryMap[smallCategory as TProduct]}
                          </h3>
                          <ul className="mb-10 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            {products.map((product, index) => {
                              const hasImage = product.image || product.link;
                              const listItemContent = (
                                <>
                                  {hasImage && (
                                    <div className="w-40 h-40 flex items-center justify-center overflow-hidden">
                                      <img
                                        className="max-w-full max-h-full"
                                        src={hasImage}
                                        alt={product.name}
                                      />
                                    </div>
                                  )}
                                  <h4
                                    className={`mt-4 text-lg font-semibold ${
                                      !hasImage ? "mt-0 text-center" : ""
                                    }`}
                                  >
                                    {product.name}
                                  </h4>
                                </>
                              );

                              return (
                                <li
                                  key={index}
                                  className={`flex flex-col text-center ${
                                    hasImage ? "items-center" : "justify-center"
                                  } p-6 border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50 hover:shadow-lg transition duration-300 cursor-pointer`}
                                >
                                  {product.originalSrc ? (
                                    <a href={product.originalSrc}>
                                      {listItemContent}
                                    </a>
                                  ) : (
                                    listItemContent
                                  )}
                                </li>
                              );
                            })}
                          </ul>

                          {smallCategory === "hearing-screening" && (
                            <p className="italic text-sm text-gray-600 mt-10 text-left mb-16">
                              {t("i18n_product_note")}
                            </p>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </>
  );
}

export default Products;
