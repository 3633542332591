import { Link } from "react-router-dom";

import MobileMenu from "./mobile/MobileMenu";
import "../styles/Header.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Header(props: any) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  /**
   * Toggles the mobile menu.
   */
  const handleMenuClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const header = document.querySelector(".header__container");

    const onScroll = () => {
      let scrollTop = document.documentElement.scrollTop;

      if (!isMobileMenuOpen) {
        // Only hide header when mobile menu is not open
        if (scrollTop > lastScrollTop) {
          header?.classList.add("header__container--hidden");
        } else {
          header?.classList.remove("header__container--hidden");
        }
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [isMobileMenuOpen]); // Add isMobileMenuOpen to dependency array

  return (
    <div className="header__container">
      <header className="flex bg-background items-center justify-between h-[70px]">
        <a href="/" className="logo">
          <img
            style={{ width: "100px", height: "auto" }}
            src={process.env.PUBLIC_URL + "/main_logo.svg"}
            alt="Company Logo"
          />
        </a>
        <nav className="flex p-4 ml-auto ">
          <div className="desktop-menu space-x-5 mr-4">
            <Link className="hover:opacity-50" to="/about">
              {t("i18n_header_about")}
            </Link>
            <Link className="hover:opacity-50" to="/products">
              {t("i18n_header_products")}
            </Link>
            <Link className="hover:opacity-50" to="/announce">
              {t("i18n_header_announce")}
            </Link>
            <Link className="hover:opacity-50" to="/contact">
              {t("i18n_header_contact")}
            </Link>
          </div>

          <MobileMenu onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
        </nav>
      </header>

      {/* Mobile Menu */}

      <ul
        className={`bg-neutral-dark flex flex-col justify-start items-center text-3xl font-bold mobile-menu__container ${
          isMobileMenuOpen ? "mobile-menu__container--open" : ""
        }`}
      >
        <li className="p-4 mt-10">
          <Link onClick={handleMenuClick} to="/">
            Home
          </Link>
        </li>
        <li className="p-4 mt-10">
          <Link onClick={handleMenuClick} to="/about">
            About
          </Link>
        </li>
        <li className="p-4 mt-10">
          <Link onClick={handleMenuClick} to="/products">
            Products
          </Link>
        </li>
        <li className="p-4 mt-10">
          <Link onClick={handleMenuClick} to="/announce">
            Announcement
          </Link>
        </li>
        <li className="p-4 mt-10">
          <Link onClick={handleMenuClick} to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
}
