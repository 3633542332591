import { useTranslation } from "react-i18next";
import useFetchAnnouncements from "../hooks/useFetchAnnouncements";
import { useNavigate } from "react-router-dom";

export default function AnnounceTable() {
  const { t } = useTranslation();

  const announcements = useFetchAnnouncements();
  const navigate = useNavigate();

  const handleRowClick = (announcementId: string) => {
    navigate(`/announce/${announcementId}`);
  };

  return (
    <div className="container lg:w-[50%] mx-auto p-4">
      <table className="w-full bg-white rounded-lg overflow-hidden shadow-md">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-3 px-6 text-left text-sm font-semibold text-gray-700">
              {t("i18n_announcements_table_row_1_title")}
            </th>
            <th className="py-3 px-6 text-left text-sm font-semibold text-gray-700">
              {t("i18n_announcements_table_row_2_title")}
            </th>
          </tr>
        </thead>
        <tbody>
          {announcements.length === 0 ? (
            // Adjust here for the loading state
            <tr>
              <td colSpan={3} className="text-center py-3">
                Loading...
              </td>
            </tr>
          ) : (
            announcements.map((announcement) => (
              <tr
                key={announcement.id}
                className="border-t border-gray-200 hover:bg-gray-50 cursor-pointer"
                onClick={() => handleRowClick(announcement.id)}
              >
                <td className="py-3 px-6 text-gray-800">
                  {announcement.title}
                </td>
                <td className="py-3 px-6 text-gray-800">
                  {announcement.publishedDate || announcement.modifiedDate}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
