import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import LanguageSwitcher from "./components/LanguageSwitcher";
// import AOS from "aos";
// import "aos/dist/aos.css";

function App() {
  return (
    <div className="flex flex-col h-screen">
      <div
        style={{
          position: "fixed",
          bottom: "2rem",
          right: "2rem",
          zIndex: 10,
        }}
      >
        <LanguageSwitcher />
      </div>
      <ScrollToTop />
      <Header />
      <main className="flex-1">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default App;
