import { useEffect, useState } from "react";
import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  query,
} from "firebase/database";

export default function useFetchAnnouncements() {
  const [announcements, setAnnouncements] = useState<any[]>([]);

  useEffect(() => {
    const db = getDatabase();
    const announcementsRef = query(
      ref(db, "announcements"),
      orderByChild("publishedDate")
    );

    onValue(announcementsRef, (snapshot) => {
      const data = snapshot.val();
      const loadedAnnouncements = [];

      for (const id in data) {
        loadedAnnouncements.push({ id, ...data[id] });
      }

      // Sort announcements in descending order by publishedDate
      loadedAnnouncements.sort((a, b) =>
        b.publishedDate.localeCompare(a.publishedDate)
      );

      setAnnouncements(loadedAnnouncements);
    });
  }, []);

  return announcements;
}
