const RetCam3 = process.env.PUBLIC_URL + "/products/retcam3.png";
const Algo5 = process.env.PUBLIC_URL + "/products/algo5.png";

const PRODUCTS: TProductItem[] = [
  // Hearing Screening
  {
    name: "Algo7i",
    description:
      "The ALGO 7i Newborn Hearing Screener is a portable, user-friendly, and reliable AABR device that can be used for newborn hearing screening. It is the only AABR device that can be used for neonatal hearing screening.",
    link: "https://natus.com/wp-content/uploads/Algo7i_product-thumbnail.png",
    bigCategory: "NICU/pediatric",
    smallCategory: "hearing-screening",
    originalSrc: "https://natus.com/sensory/algo-7i/",
  },
  {
    name: "Algo 5",
    image: Algo5,
    description:
      "The ALGO 5 Newborn Hearing Screener is a portable, user-friendly, and reliable AABR device that can be used for newborn hearing screening. It is the only AABR device that can be used for neonatal hearing screening.",
    link: "https://www.claritymedical.com/algo-5",
    bigCategory: "NICU/pediatric",
    smallCategory: "hearing-screening",
    originalSrc: "https://natus.com/sensory/algo-5/",
  },

  // Eye Imaging
  {
    name: "RetCam Envision",
    description:
      "RetCam Envision is a portable, easy-to-use, and non-invasive imaging system that provides high-resolution color images of the retina and anterior segment of the eye. It is the only portable wide-field imaging system that can be used for neonatal imaging.",
    link: "https://natus.com/wp-content/uploads/Retcam-Envision_product-thumbnail.png",
    bigCategory: "NICU/pediatric",
    smallCategory: "eye-imaging",
    originalSrc: "https://natus.com/sensory/retcam-envision/",
  },
  {
    name: "RetCam 3",
    image: RetCam3,
    description:
      "The RetCam 3 is the latest version of the RetCam series. It is a portable, easy-to-use, and non-invasive imaging system that provides high-resolution color images of the retina and anterior segment of the eye. It is the only portable wide-field imaging system that can be used for neonatal imaging.",
    link: "https://www.claritymedical.com/retcam-3",
    originalSrc: "https://natus.com/sensory/newborn-hearing-screening/",
    bigCategory: "NICU/pediatric",
    smallCategory: "eye-imaging",
  },

  // Jaundice Management
  {
    name: "neoBLUE blanket",
    description:
      "neoBlUE blanket is a portable, easy-to-use, and non-invasive imaging system that provides high-resolution color images of the retina and anterior segment of the eye. It is the only portable wide-field imaging system that can be used for neonatal imaging.",
    link: "https://img.medicalexpo.com/images_me/photo-g/76900-17860951.webp",
    bigCategory: "NICU/pediatric",
    smallCategory: "jaundice-mgmt",
    originalSrc: "https://natus.com/sensory/neoblue-blanket/",
  },

  // Delivery
  {
    name: "Kiwi",
    description:
      "Kiwi is a portable, easy-to-use, and non-invasive imaging system that provides high-resolution color images of the retina and anterior segment of the eye. It is the only portable wide-field imaging system that can be used for neonatal imaging.",
    link: "https://www.laborie.com/wp-content/uploads/2021/07/Kiwi-Header-no-logo-2.png.webp",
    bigCategory: "OB/GYN,neonatal",
    smallCategory: "delivery",
    originalSrc:
      "https://www.laborie.com/product/kiwi/?utm_term=kiwi%20assisted%20delivery&utm_campaign=United%20States%20-%20Kiwi%20-%20General&utm_source=adwords&utm_medium=ppc&hsa_acc=8902518605&hsa_cam=14997409853&hsa_grp=134235868208&hsa_ad=554243394218&hsa_src=g&hsa_tgt=kwd-356167685907&hsa_kw=kiwi%20assisted%20delivery&hsa_mt=p&hsa_net=adwords&hsa_ver=3&gclid=Cj0KCQiAwbitBhDIARIsABfFYIIgZh3LDT8MScA4-BwHdtUbdu7zS8tF44JGtulfVDomdE_haWtLq6EaAj6TEALw_wcB",
  },
];

export default PRODUCTS;
