import { useTranslation } from "react-i18next";
const networkImgSrc = process.env.PUBLIC_URL + "/sales_network.png";

export default function About() {
  const { t } = useTranslation();

  return (
    <section className=" h-full flex items-center justify-center">
      <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 py-10">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6">
            {t("i18n_about_subtitle")}
          </h2>
          <p className="text-lg text-gray-600">{t("i18n_about_title")}</p>
        </div>

        <div className="grid md:grid-cols-1 gap-10">
          {/* New History Section */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg ">
            <h3 className="text-2xl font-semibold mb-6">
              {t("i18n_about_history_title")}
            </h3>
            <ul className="space-y-4 text-gray-700 px-4">
              <li>
                <strong>Sep 2021:</strong> {t("i18n_about_history_2021_sep")}
              </li>
              <li>
                <strong>March 2012:</strong>{" "}
                {t("i18n_about_history_2012_march")}
              </li>
              <li>
                <strong>May 2010:</strong> {t("i18n_about_history_2010_may")}
              </li>
              <li>
                <strong>May 2009:</strong> {t("i18n_about_history_2009_may")}
              </li>
              <li>
                <strong>July 1, 2006:</strong>{" "}
                {t("i18n_about_history_2006_july")}
              </li>
              <li>
                <strong>Sep 2004:</strong> {t("i18n_about_history_2004_sep")}
              </li>
              <li>
                <strong>June 2003:</strong> {t("i18n_about_history_2003_june")}
              </li>
              <li>
                <strong>July 2002:</strong> {t("i18n_about_history_2002_july")}
              </li>
              <li>
                <strong>Feb 2002:</strong> {t("i18n_about_history_2002_feb")}
              </li>
              <li>
                <strong>Jan 2002:</strong> {t("i18n_about_history_2002_jan")}
              </li>
              <li>
                <strong>2001:</strong> {t("i18n_about_history_2001")}
              </li>
            </ul>
          </div>
          {/* Sales Pipeline Section */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-6">
              {t("i18n_about_specialties_title")}
            </h3>
            <ul className="grid grid-cols-2 gap-4 text-gray-700 px-4">
              <li>{t("i18n_internal_medicine")}</li>
              <li>{t("i18n_sports_medicine")}</li>
              <li>{t("i18n_cardiology")}</li>
              <li>{t("i18n_ob_gyn")}</li>
              <li>{t("i18n_urology")}</li>
              <li>{t("i18n_pediatrics")}</li>
              <li>{t("i18n_orthopedics")}</li>
              <li>{t("i18n_rehabilitation_medicine")}</li>
              <li>{t("i18n_family_medicine")}</li>
              <li>{t("i18n_elderly_medicine")}</li>
              <li>{t("i18n_ent")}</li>
            </ul>

            <p className="italic text-sm text-gray-600 pl-4 mt-10 text-left">
              {t("i18n_about_specialities_note")}
            </p>

            <h3 className="text-2xl font-semibold mt-12 mb-6">
              {t("i18n_about_distribution_title")}
            </h3>
            <div className="md:flex md:justify-between px-4 py-1 h-fit space-y-2 md:space-y-0">
              <ul className="grid grid-cols-1 text-gray-700 mb-6 md:mb-0 space-y-4">
                <li>{t("i18n_about_seoul")}</li>
                <li>{t("i18n_about_gyeonggi")}</li>
                <li>{t("i18n_about_gangwon")}</li>
                <li>{t("i18n_about_chungcheongbuk")}</li>
                <li>{t("i18n_about_chungcheongnam")}</li>
                <li>{t("i18n_about_jeollabuk")}</li>
                <li>{t("i18n_about_jeollanam")}</li>
                <li>{t("i18n_about_gyeongsangbuk")}</li>
                <li>{t("i18n_about_gyeongsangnam")}</li>
                <li>{t("i18n_about_jeju")}</li>
              </ul>
              <img
                className="w-full md:w-[30%] mx-auto"
                src={networkImgSrc}
                alt="sales network"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
