import "./i18n";
import "aos/dist/aos.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ErrorPage from "./error-page";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Products from "./components/Products";
import Homepage from "./components/Homepage";
import About from "./components/About";
import Contact from "./components/Contact";
import AOS from "aos";
import SignIn from "./components/auth/SignIn";
import Announce from "./components/Announce";
import AnnounceDetail from "./components/AnnounceDetail";

AOS.init();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      // {
      //   path: "signin",
      //   element: <SignIn />,
      // },
      {
        path: "announce",
        element: <Announce />,
      },
      {
        path: "announce/:id",
        element: <AnnounceDetail />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
